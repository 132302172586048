"use strict";

(function () {
  'use strict';

  var nameContainerLanguage = {
    title: 'Defina o nome da sua pesquisa',
    label: 'Título da pesquisa',
    placeholder: 'Digite um título',
    requiredText: 'O nome da pesquisa é obrigatório'
  };
  window.moduleExport({
    nameContainerLanguage: nameContainerLanguage
  }, typeof module !== 'undefined' && module);
})();